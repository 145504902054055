.card-wrapper {
  position: relative;
  margin-bottom: 16px;
}
.card-type {
  position: absolute;
  top: 28px;
  right: 10px;
  transform: translateY(-50%);
  width: 50px;
  display: flex;
  pointer-events: none;
}

.card-tax-warning{
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  border: 1px solid #FFE5E2;
  border-radius: 16px;
  background: #FFF7F6;
  box-shadow: 0px 0px 8px 0px rgba(15, 74, 132, 0.10);
}