.pagination-container {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.pagination-next,
.pagination-previous {
  width: 66px;
  height: 32px;
  background: #2f81ef;
  border: 1px solid #2f81ef;
  box-shadow:
    0px 3px 1px -2px rgba(16, 66, 122, 0.2),
    0px 2px 2px rgba(16, 66, 122, 0.14),
    0px 1px 5px rgba(16, 66, 122, 0.12);
  border-radius: 20px;
  color: #ffffff;

  &.disabled {
    background: #dadada;
    border: 1px solid #dadada;
    cursor: no-drop;
    color: #252631;
  }

  @media (max-width: 500px) {
    width: 60px;
  }
}

.pagination-next,
.pagination-previous,
.pagination-page {
  font-size: 15px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  user-select: none;
}

.pagination-break {
  margin-right: 12px;
  @media (max-width: 500px) {
    margin-right: 8px;
  }
}

.pagination-previous {
  margin-right: 12px;
  @media (max-width: 500px) {
    margin-right: 8px;
    min-width: 60px;
  }
}

.pagination-next {
  margin-left: 12px;
  @media (max-width: 500px) {
    margin-left: 8px;
    min-width: 60px;
  }
}

.pagination-page {
  width: 42px;
  background: #ffffff;
  border: 1px solid #2f81ef;
  box-shadow:
    0px 3px 1px -2px rgba(16, 66, 122, 0.2),
    0px 2px 2px rgba(16, 66, 122, 0.14),
    0px 1px 5px rgba(16, 66, 122, 0.12);
  border-radius: 20px;
  color: #2f81ef;
  min-width: 40px;

  &:not(:nth-last-child(2)) {
    margin-right: 12px;
  }

  @media (max-width: 500px) {
    width: 30px;
    min-width: 30px;
    &:not(:nth-last-child(2)) {
      margin-right: 8px;
    }
  }
}

.pagination-active {
  background: #2f81ef;
  color: #ffffff;
}

.pagination-previous-link,
.pagination-next-link,
.pagination-page-link {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-break {
  pointer-events: none;
  user-select: none;
}

.pagination-next-no-bg,
.pagination-previous-no-bg,
.pagination-page-no-bg {
  cursor: pointer;
  user-select: none;
}

.pagination-next-no-bg,
.pagination-previous-no-bg {
  width: 66px;
  height: 32px;
  border-radius: 20px;
  color: #2f81ef;

  &.disabled {
    cursor: no-drop;
    color: #6c757d;
  }
}
.pagination-previous-no-bg {
  @media (max-width: 530px) {
    margin-right: 0;
    min-width: unset;
    width: 50px;
  }
}
.pagination-next-no-bg {
  @media (max-width: 530px) {
    margin-left: 0;
    min-width: unset;
    width: 50px;
  }
}

.pagination-active-no-bg {
  color: #2f81ef;
  background: #f5f9ff;
  border-radius: 20px;
}

.pagination-page-no-bg {
  width: 42px;
  color: #2f81ef;
  min-width: 40px;

  &:not(:nth-last-child(2)) {
    margin-right: 12px;
  }
  @media (max-width: 530px) {
    min-width: unset;
    width: 22px;
    &:not(:nth-last-child(2)) {
      margin-right: 3px;
    }
  }
}

.pagination-break-no-bg {
  margin-right: 12px;
  @media (max-width: 530px) {
    margin-right: 3px;
  }
}